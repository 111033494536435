import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { VersionInfo } from '../types/Versions';
import { getLatestVersion } from '../api/versions';

export const VERSION_QUERY_KEY = ['version'];

export const useVersionQuery = (
  enabled = true,
  pollingInterval: number = 5 * 60 * 1000
): UseQueryResult<VersionInfo, Error> =>
  useQuery({
    queryKey: VERSION_QUERY_KEY,
    queryFn: getLatestVersion,
    enabled,
    refetchInterval: pollingInterval,
    refetchIntervalInBackground: true,
    retry: 3,
    staleTime: 1 * 60 * 1000, // 1 minute
  });
