import { ReactNode } from 'react';
import { VersionContext } from '../../providers/VersionContextProvider';
import { VersionInfo } from '../../types/Versions';
import { useVersionQuery } from '../../hooks/useVersion';

interface VersionCheckerProps {
  children: ReactNode;
  currentVersion: VersionInfo;
  pollingInterval?: number;
}

export const VersionChecker: React.FC<VersionCheckerProps> = ({ children, currentVersion, pollingInterval }) => {
  const { data: latestVersion, isLoading, error, refetch } = useVersionQuery(true, pollingInterval);

  return (
    <VersionContext.Provider
      value={{
        currentVersion,
        latestVersion: latestVersion ?? null,
        isLoading,
        error: error || null,
        refetch: async () => {
          await refetch();
        },
      }}
    >
      {children}
    </VersionContext.Provider>
  );
};
