import { useEffect, useState } from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';

import { useVersion } from '../../providers/VersionContextProvider';

const EXCLUDED_URL_PATH_PATTERNS = ['^/interview(/.*)?$', '^/interview-ai$'].map((pattern) => new RegExp(pattern));

export const VersionMismatchBanner = () => {
  const { currentVersion, latestVersion, isLoading, error } = useVersion();
  const [shouldShow, setShouldShow] = useState(true);

  const checkShouldShowBanner = () => {
    const isExcludedUrlPath = EXCLUDED_URL_PATH_PATTERNS.some((pattern) => pattern.test(window.location.pathname));
    setShouldShow(!isExcludedUrlPath);
  };

  const handleRefresh = async (): Promise<void> => {
    window.location.reload();
  };

  useEffect(() => {
    checkShouldShowBanner();
    const handleUrlChange = () => {
      checkShouldShowBanner();
    };

    window.addEventListener('popstate', handleUrlChange);

    const originalPushState = history.pushState;
    history.pushState = function pushState(...args) {
      originalPushState.apply(this, args);
      handleUrlChange();
    };

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      history.pushState = originalPushState;
    };
  }, []);

  const isOutdated = latestVersion && currentVersion.clientGitRef !== latestVersion.clientGitRef;

  if (isLoading || error || !latestVersion || !isOutdated || !shouldShow) {
    return null;
  }

  return (
    <div className="flex flex-row bg-orange-600 text-orange-50 items-center fixed justify-center top-0 h-12 mx-auto w-full">
      <ExclamationIcon className="h-6 w-6 mx-2" aria-hidden="true" />
      <span>
        Please{' '}
        <button className="underline pointer text-orange-50" onClick={handleRefresh}>
          refresh
        </button>{' '}
        to get the latest version!
      </span>
    </div>
  );
};
