import { createContext, useContext } from 'react';
import { VersionContextType } from '../types/Versions';

export const VersionContext = createContext<VersionContextType | null>(null);

export const useVersion = (): VersionContextType => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error('useVersion must be used within a VersionProvider');
  }
  return context;
};
