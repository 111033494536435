import { AxiosError } from 'axios';
import { VersionInfo } from '../types/Versions';
import axiosInstance from '../utils/axios';

export class VersionApiError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'VersionApiError';
  }
}

export async function getLatestVersion(): Promise<VersionInfo> {
  try {
    const { data } = await axiosInstance.get<VersionInfo>('/api/version-metadata');
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new VersionApiError(
        error.response?.data?.message || 'Failed to fetch version info',
        error.response?.status
      );
    }
    throw new VersionApiError(error instanceof Error ? error.message : 'Unknown error occurred');
  }
}
